import React from 'react';
import {
  Box,
  Button,
  Heading,
  ModalProps,
  OutlineButton,
  Paragraph,
} from '@qga/roo-ui/components';
import { Col, Row } from 'src/primitives/Grid';
import { ModalWrapper } from '../ModalWrapper';

interface IProps extends ModalProps {
  action: () => Promise<any>;
  label: string;
  loading?: boolean;
  loadingMessage?: string;
  message: string;
  secondaryMessage?: string;
  onRequestClose: () => void;
  title: string;
}

export const ConfirmationModal = ({
  action,
  isOpen,
  label,
  loading,
  loadingMessage = 'Loading...',
  message,
  onRequestClose,
  secondaryMessage,
  title,
}: IProps) => (
  <>
    <ModalWrapper
      isOpen={isOpen}
      heading={<Heading.h3 mb="15px">{title}</Heading.h3>}
      onClose={onRequestClose}
    >
      <Paragraph fontSize={'18px'} mb={secondaryMessage ? '10px' : '25px'}>
        {message}
      </Paragraph>

      {secondaryMessage && (
        <Paragraph fontSize={'base'} mb={8}>
          {secondaryMessage}
        </Paragraph>
      )}

      <Box>
        <Row>
          <Col width={1 / 2}>
            <OutlineButton
              block={true}
              variant="primary"
              onClick={onRequestClose}
              disabled={loading}
              data-testid="confirmation-modal-cancel"
            >
              Cancel
            </OutlineButton>
          </Col>

          <Col width={1 / 2} mb={[3, 0]}>
            <Button
              block={true}
              variant="primary"
              onClick={() =>
                action().then(onRequestClose).catch(onRequestClose)
              }
              disabled={loading}
              data-testid="confirmation-modal-confirm"
            >
              {loading ? loadingMessage : label}
            </Button>
          </Col>
        </Row>
      </Box>
    </ModalWrapper>
  </>
);
