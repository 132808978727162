import chunk from 'lodash/chunk';
import kebabCase from 'lodash/kebabCase';
import map from 'lodash/map';
import { rem } from 'polished';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  Box,
  Container,
  ExternalLink,
  Flex,
  Heading,
  Icon,
  Link,
  ListItem,
  Paragraph,
} from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';
import UnauthenticatedLayout from 'src/layouts/UnauthenticatedLayout';
import { AlphaList } from 'src/primitives/AlphaList';
import { DecimalList } from 'src/primitives/DecimalList';

import SupplierAgreementPdf from 'src/components/PropertySidebar/assets/V8_standard_agreement.pdf';

const Anchor = styled.a`
  display: block;
  position: relative;
  visibility: hidden;
  top: -${rem('90px')};
`;

const SectionLink = ({ name }: { name: string }) => (
  <Paragraph mt={4} mb={0}>
    <Link smooth={true} as={HashLink} to={`#${kebabCase(name)}`}>
      <Icon name="arrowDownward" size={18} mr={1} />
      {name}
    </Link>
  </Paragraph>
);

const TopLink = () => (
  <Paragraph mt={4} mb={0}>
    <Link smooth={true} as={HashLink} to="#top">
      <Icon name="arrowUpward" size={18} mr={1} />
      Back to top
    </Link>
  </Paragraph>
);

export const Terms = () => (
  <UnauthenticatedLayout title="Terms of use">
    <Container maxWidth="wide">
      <Heading.h2 as="h1" mt={8} mb={[4, 8]}>
        Qantas Supplier Portal Terms of Use ('Terms of Use')
      </Heading.h2>

      <Anchor id="top" />

      <Box>
        <Flex wrap="wrap">
          {chunk(Object.keys(SECTIONS), 4).map((sectionChunk, i) => (
            <Box width={[1, 1 / 2, 1 / 3]} key={i}>
              {sectionChunk.map((section, k) => (
                <SectionLink name={section} key={k} />
              ))}
            </Box>
          ))}
        </Flex>
      </Box>

      {map(SECTIONS, (value, key) => (
        <Box my={8} key={key}>
          <Anchor id={kebabCase(key)} />
          <Heading.h2 fontWeight="bold">{key}</Heading.h2>

          {value}

          <TopLink />
        </Box>
      ))}

      <Heading.h6>Version: 14 March 2024</Heading.h6>
    </Container>
  </UnauthenticatedLayout>
);

const SECTIONS = {
  Introduction: (
    <DecimalList prefix="1">
      <ListItem>
        Your access to and use of the Qantas Supplier Portal and the Websites is
        governed by the
        <Link href={SupplierAgreementPdf} target="_blank">
          &nbsp;Qantas Accommodation Group Pty Ltd Hotels Supplier
          Agreement&nbsp;
        </Link>
        (‘<strong>Agreement</strong>') and these Terms of Use.
      </ListItem>
      <ListItem>
        Expressions in these Terms of Use have the same meaning as in the
        Agreement. The Agreement will prevail to the extent of any inconsistency
        with these Terms of Use.
      </ListItem>
      <ListItem>
        By using the Qantas Supplier Portal you agree to be bound by these Terms
        of Use, as amended from time to time. By continuing to use the Qantas
        Supplier Portal you agree to be bound by any updated Terms of Use.
      </ListItem>
    </DecimalList>
  ),
  'Loading Information onto the Qantas Supplier Portal': (
    <DecimalList prefix="2">
      <ListItem>
        You warrant that you are legally authorised to act on behalf of the
        Property to load Property Details and Offers and make changes to the
        Property Details and Offers loaded, on to the Qantas Supplier Portal.
      </ListItem>
      <ListItem>
        You agree not to load any information onto the Qantas Supplier Portal
        that is inappropriate, unlawful, obscene, threatening, abusive,
        defamatory, and/or encourages behaviour that may give rise to criminal
        activity, is considered adult content, is a breach of intellectual
        property, or may give rise to civil liability.
      </ListItem>
      <ListItem>
        You must not load any information onto the Qantas Supplier Portal that
        contains any virus, malware, spyware, cookies, tracking software or any
        executable file of any kind.
      </ListItem>
      <ListItem>
        You must not alter, delete or amend any part of the Qantas Supplier
        Portal or interfere with the operation of the Qantas Supplier Portal, or
        attempt to do any of those things.
      </ListItem>
      <ListItem>
        You must not load any Offer onto the Qantas Supplier Portal that the
        Property cannot provide or that the Property may not be able to provide,
        including due to insufficient capacity or any other reason.
      </ListItem>
      <ListItem>
        We can alter, amend or deactivate the Qantas Supplier Portal at any time
        and for any reason whatsoever, including by deleting or removing any
        information, Properties or Offers contained on the Qantas Supplier
        Portal. We may choose to do so for security purposes, to prevent fraud,
        to fix issues or errors or for operational reasons.
      </ListItem>
    </DecimalList>
  ),
  'Provision of Products and Services': (
    <DecimalList prefix="3">
      <ListItem>
        A Booking represents a contract between the You and the Customer. We
        take Bookings solely as your agent and no contract will form between us
        and the Customer.
      </ListItem>
      <ListItem>
        You warrant that all products and services you load onto the Qantas
        Supplier Portal will be supplied exactly as stated. You agree to use all
        data fields as intended and labelled in the Qantas Supplier Portal.
      </ListItem>
      <ListItem>
        You agree that if the Property is able to be utilised by a person with a
        permanent or temporary impairment (e.g. deaf, hearing impaired, blind,
        vision impaired, any strength or mobility limitation, intellectual
        impairment or travelling with a service dog), that you will load onto
        the Qantas Supplier Portal accurate information about the accessibility
        of that Property.
      </ListItem>
    </DecimalList>
  ),
  'Privacy and Intellectual Property Rights': (
    <DecimalList prefix="4">
      <ListItem>
        Any personal information collected by Qantas on the Qantas Supplier
        Portal will be treated in accordance with
        <ExternalLink
          underline={true}
          href="https://www.qantas.com/au/en/support/privacy-and-security.html"
        >
          Qantas&apos;s Privacy Policy
        </ExternalLink>
        .
      </ListItem>
      <ListItem>
        The material contained on the Qantas Supplier Portal and our Websites is
        protected by copyright.
      </ListItem>
      <ListItem>
        Except to the extent permitted by relevant copyright legislation, you
        must not use, copy, modify, transmit, store, publish or distribute the
        material on the Qantas Supplier Portal or our Websites, or create any
        other material using material on our websites, without obtaining the
        prior written consent of Qantas.
      </ListItem>
      <ListItem>
        Our trademarks (whether registered or unregistered) and logos must not
        be used or modified in any way without obtaining the prior written
        consent of Qantas.
      </ListItem>
      <ListItem>
        The material, products, technology and processes contained in our
        websites may be the subject of other intellectual property rights owned
        by Qantas or by third parties.
      </ListItem>
      <ListItem>
        No licence is granted in respect of those intellectual property rights
        other than as set out in these Terms of Use or the Agreement. Your use
        of our websites must not in any way infringe the intellectual property
        rights of any person.
      </ListItem>
    </DecimalList>
  ),
  Cookies: (
    <DecimalList prefix="5">
      <ListItem>
        Cookies are small pieces of data stored on the web browser on your
        computer. The Qantas Supplier Portal may:
        <AlphaList>
          <ListItem>store one or more cookies in your browser;</ListItem>
          <ListItem>
            request your browser to transmit this data back to the web server;
            or
          </ListItem>
          <ListItem>
            request your browser to transmit a cookie that has been stored on
            your browser by another site within the same internet domain. For
            example, all subdomains in the domain Qantas.com may retrieve a
            cookie set by the webserver Qantas.com.
          </ListItem>
        </AlphaList>
      </ListItem>
      <ListItem>
        By using cookies, the Qantas Supplier Portal can track information about
        use of the portal and provide customised content. Most web browsers can
        be configured to notify the user when a cookie is received, allowing you
        to either accept or reject it. You may also inspect the cookies stored
        by your web browser and remove any that you do not want.
      </ListItem>
      <ListItem>
        If you disable the use of cookies on your web browser or remove or
        reject specific cookies from this web site or linked sites, then you may
        not be able to gain access to all the content and facilities of the
        Qantas Supplier Portal.
      </ListItem>
      <ListItem>
        We can send cookies or other software to your computer system for the
        purposes of tracking use of and access to the Qantas Supplier Portal.
        You must allow us to send such software to the computer system you use
        to access the Qantas Supplier Portal and you must not take any steps to
        remove or deactivate.
      </ListItem>
    </DecimalList>
  ),
  'Availability and Liability': (
    <DecimalList prefix="6">
      <ListItem>
        We will endeavour to have the Qantas Supplier Portal and the Websites
        available at most times, however to the extent permitted by Law, we make
        no representation and give no warranty as to the functionality,
        availability, use, operation or any other aspect of the Qantas Supplier
        Portal or the Website.
      </ListItem>
      <ListItem>
        To the extent permitted by Law you release Qantas and any Qantas Group
        Company and their respective Personnel from any and all Damages arising
        from or in any way related to your access to or use of the Qantas
        Supplier Portal or the Website, including but not limited to:
        <AlphaList>
          <ListItem>
            any unauthorised access to or use of the Qantas Supplier Portal;
          </ListItem>
          <ListItem>
            any in ability to access, malfunction, reduced function or failure
            of the Qantas Supplier Portal or a Website;
          </ListItem>
          <ListItem>
            any loss of data on the Qantas Supplier Portal; and
          </ListItem>
          <ListItem>
            any viruses, bugs or other harmful software that may be transmitted
            through the Qantas Supplier Portal.
          </ListItem>
        </AlphaList>
      </ListItem>
    </DecimalList>
  ),
  'Suspension and Termination': (
    <DecimalList prefix="7">
      <ListItem>
        We may suspend or close down the Qantas Supplier Portal at anytime. We
        will attempt to give you advance notice of that.
      </ListItem>
      <ListItem>
        You may terminate your access to the Qantas Supplier Portal at anytime
        by contacting us and requestion we remove your access.
      </ListItem>
      <ListItem>
        We may suspend or terminate your access to the Qantas Supplier Portal
        if:
        <AlphaList>
          <ListItem>
            you breach the terms of your Qantas Hotels Agreement or if that
            agreement is terminated;
          </ListItem>
          <ListItem>
            if the Qantas Supplier Portal is Panel is closed down;
          </ListItem>
          <ListItem>if you breach these Terms of Use; or</ListItem>
          <ListItem>as otherwise set out in these Terms of Use.</ListItem>
        </AlphaList>
      </ListItem>
    </DecimalList>
  ),
  Severance: (
    <DecimalList prefix="8">
      <ListItem>
        If any clause of these Terms of Use is found by any court or other
        authority of competent jurisdiction to be invalid, illegal or
        unenforceable, then that clause shall, to the extent required, be deemed
        modified to the minimum extent necessary to make its valid, legal and
        enforceable. If such modification is not possible, the relevant clause
        shall be deemed severed from these Terms of Use. Any modification or
        severance of any clause shall not affect the validity and enforceability
        of the balance of these Terms of Use.
      </ListItem>
    </DecimalList>
  ),
};
