import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, LinkProps } from '@qga/roo-ui/components';

export const ContactLink: React.FC<LinkProps> = ({ children, ...props }) => (
  <Link
    {...props}
    as={RouterLink}
    to="/contact-us"
    rel="noopener noreferrer"
    target="_blank"
  >
    {children ? children : 'contact us'}
  </Link>
);
