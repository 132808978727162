import React, { ReactNode, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useLocation } from 'react-router-dom';

type Props = {
  title: string;
  children?: ReactNode;
};
export const PageView = (props: Props) => {
  const { pathname } = useLocation();
  const { trackPageView } = useAnalytics();
  useEffect(() => {
    trackPageView(props.title);
  }, [pathname, trackPageView, props.title]);

  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      {props.children}
    </>
  );
};
