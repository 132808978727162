import { Box, Button, Flex, Link } from '@qga/roo-ui/components';
import { Formik } from 'formik';
import { Form } from 'src/components/Form';
import React, { useState, useCallback, MouseEventHandler } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { object, string, InferType } from 'yup';
import {
  CognitoError,
  RecaptchaError,
  LockedOutError,
  CompromisedError,
} from 'src/components/SignInErrorAlerts';
import InputField from 'src/fields/InputField';
import { PasswordField } from 'src/fields/PasswordField';
import useRecaptcha from 'src/hooks/useRecaptcha';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useHandleSubmit } from 'src/lib/form';

interface IProps {
  onSignIn: (username: string, password: string) => Promise<any>;
}

const formSchema = object().shape({
  password: string().ensure().required('Enter your password'),
  username: string()
    .ensure()
    .required('Enter your email')
    .email('Enter a valid email'),
});

enum ErrorTypes {
  Generic = 'GENERIC',
  LockedOut = 'LIMIT_EXCPTN',
  InvalidPassword = 'INVALID_PWRD',
}

type FormValues = InferType<typeof formSchema>;

export const SignInForm = ({ onSignIn }: IProps) => {
  const formName = 'SignInForm';
  const { trackSignIn, trackCtaClick } = useAnalytics();
  const [hasError, setHasError] = useState<ErrorTypes | undefined>(undefined);
  const { validateAction, hasRecaptchaError } = useRecaptcha();

  const handleClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      trackCtaClick({
        group: 'Content',
        item: 'Login',
        text: event.currentTarget.innerText ?? '',
        type: 'link',
        url: event.currentTarget.href,
      });
    },
    [trackCtaClick],
  );

  const handleSubmit = useHandleSubmit(formName, async (values: FormValues) => {
    const { username, password } = values;
    try {
      const { validateToken } = await validateAction('qh_extranet_login');

      if (validateToken?.success) {
        const user = await onSignIn(username, password);
        trackSignIn({
          current: {
            user_id: user.uuid,
            user_role: user.role,
          },
        });
        setHasError(undefined);
      }
    } catch (error) {
      let formError = ErrorTypes.Generic;
      if (error && typeof error === 'object' && 'code' in error) {
        if (error?.code === 'LimitExceededException')
          formError = ErrorTypes.LockedOut;
        else if (error?.code === 'InvalidPasswordException')
          formError = ErrorTypes.InvalidPassword;
      }

      setHasError(formError);
    }
  });

  return (
    <Formik<FormValues>
      initialValues={formSchema.cast({})}
      validationSchema={formSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          {hasError === ErrorTypes.Generic && <CognitoError />}
          {hasError === ErrorTypes.LockedOut && <LockedOutError />}
          {hasError === ErrorTypes.InvalidPassword && <CompromisedError />}
          {hasRecaptchaError && <RecaptchaError />}

          <Form
            name={formName}
            submittingMessage={null}
            errorMessage={'Please complete the following details to sign in:'}
            successMessage={null}
          >
            <InputField
              underline={false}
              label="Email address"
              type="email"
              name="username"
              autoComplete="username"
              suppressTracking
            />

            <PasswordField underline={false} label="Password" name="password" />

            <Flex alignItems="center" mt={6}>
              <Box width={1 / 2} color="greys.steel">
                <Link
                  as={RouterLink}
                  to="/forgot-password"
                  underline={true}
                  onClick={handleClick}
                >
                  Forgotten your email or password?
                </Link>
              </Box>

              <Box width={1 / 2}>
                <Button
                  variant="primary"
                  block={true}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Loading...' : 'Sign in'}
                </Button>
              </Box>
            </Flex>
          </Form>
        </>
      )}
    </Formik>
  );
};
