import React, { useCallback, useState } from 'react';
import { Box, Button, Flex } from '@qga/roo-ui/components';
import { FormikConfig, FormikProvider, useFormik } from 'formik';
import { captureException } from '@sentry/browser';
import * as yup from 'yup';
import { CognitoError, RecaptchaError } from 'src/components/SignInErrorAlerts';
import InputField from 'src/fields/InputField';
import useAuth from 'src/hooks/useAuth';
import useRecaptcha from 'src/hooks/useRecaptcha';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Form } from 'src/components/Form';

type Props = {
  onForgotPassword: (email: string) => Promise<any>;
};

const formSchema = yup.object().shape({
  email: yup
    .string()
    .required('Enter your email')
    .email('Please enter a valid email address')
    .ensure(),
});

type FormValues = yup.InferType<typeof formSchema>;

export const ForgotPasswordForm = ({ onForgotPassword }: Props) => {
  const { trackPasswordReset } = useAnalytics();
  const [hasError, setHasError] = useState(false);
  const { validateAction, hasRecaptchaError } = useRecaptcha();
  const { isAuthenticated, user } = useAuth();

  const onSubmit = useCallback<FormikConfig<FormValues>['onSubmit']>(
    async (values, formikHelpers) => {
      trackPasswordReset();
      try {
        if (isAuthenticated) {
          await onForgotPassword(values.email);
          setHasError(false);
          return;
        }
        const { validateToken } = await validateAction(
          'qh_extranet_forgot_password',
        );
        const success = Boolean(validateToken?.success);
        if (success) {
          await onForgotPassword(values.email);
          setHasError(false);
        }
      } catch (error) {
        captureException(error);
        setHasError(true);
        formikHelpers.setSubmitting(false);
      }
    },
    [
      isAuthenticated,
      onForgotPassword,
      setHasError,
      trackPasswordReset,
      validateAction,
    ],
  );

  const formik = useFormik<FormValues>({
    initialValues: formSchema.cast({ email: user?.email }),
    validationSchema: formSchema,
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      {hasError && <CognitoError />}
      {hasRecaptchaError && <RecaptchaError />}

      <Form name="ForgotPasswordForm">
        <Flex flexWrap="wrap">
          <Box width={1}>
            <InputField
              label="Email address"
              type="email"
              name="email"
              placeholder="Enter email address"
              readOnly={isAuthenticated}
              suppressTracking
            />
          </Box>

          <Box width={[1, 3 / 5, 2 / 5]}>
            <Button
              variant="primary"
              block={true}
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? 'Sending...' : 'Send'}
            </Button>
          </Box>
        </Flex>
      </Form>
    </FormikProvider>
  );
};
