import styled from '@emotion/styled/macro';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  BlockLink,
  Box,
  BoxProps,
  Container,
  Flex,
} from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';

import { QantasHotelsLogo } from 'src/primitives/QantasHotelsLogo';

interface IProps {
  children?: React.ReactNode;
}

const Wrapper = styled(Box)<BoxProps>`
  position: sticky;
  top: 0;
  z-index: ${themeGet('zIndices.header')};
`;

export const Header = ({ children }: IProps) => (
  <Wrapper boxShadow="soft" bg="white">
    <Container maxWidth="wide">
      <Flex alignItems="center" height={[rem('60px'), rem('80px')]}>
        <BlockLink as={Link} to="/">
          <QantasHotelsLogo />
        </BlockLink>

        <Box textAlign="right" ml="auto">
          {children}
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);
