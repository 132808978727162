import React from 'react';
import { useToggle, useTimeoutFn } from 'react-use';

import { FixedAlert, IFixedAlertProps } from 'src/primitives/FixedAlert';

interface IProps extends IFixedAlertProps {
  delay?: number;
}

export const DelayedAlert: React.FC<IProps> = ({
  delay = 2000,
  children,
  ...props
}) => {
  const [displayAlert, toggleAlert] = useToggle(false);

  useTimeoutFn(toggleAlert, delay);

  return <>{displayAlert && <FixedAlert {...props}>{children}</FixedAlert>}</>;
};
