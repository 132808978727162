import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Heading, Icon, Text } from '@qga/roo-ui/components';
import styled from '@emotion/styled/macro';
import { themeGet } from 'styled-system';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Col, Row } from 'src/primitives/Grid';

interface IProps {
  backTo?: string;
  children?: React.ReactNode;
  section: string;
  title?: string;
  subText?: string;
}

const StyledIcon = styled(Icon)`
  background-color: ${themeGet('colors.greys.porcelain')};
  border-radius: ${themeGet('radii.rounded')};

  &:hover {
    color: ${themeGet('colors.ui.linkHover')};
  }
`;

const PageTitle = ({ backTo, children, section, subText, title }: IProps) => {
  const { trackClick } = useAnalytics();
  return (
    <Box borderBottom={1} borderColor="border" py={4}>
      <Container>
        <Row alignItems="center">
          {backTo && (
            <Col>
              <RouterLink
                to={backTo}
                onClick={() =>
                  trackClick({
                    group: `${section}${title ? ' - ' + title : ''}`,
                    item: 'Page Title Back Button',
                    text: '<',
                    type: 'button',
                  })
                }
                aria-label="Back to Previous"
                data-testid="back-to-prev-link"
              >
                <StyledIcon
                  name="chevronLeft"
                  color="blueGrey.slateGrey"
                  size={36}
                  p={1}
                />
              </RouterLink>
            </Col>
          )}

          <Col>
            <Heading
              color="blueGrey.slateGrey"
              textStyle="caps"
              fontSize="sm"
              fontWeight="bold"
              letterSpacing="wide"
              as="h1"
              mb={0}
            >
              {section}
            </Heading>

            {title && (
              <Heading.h2 mt={1} mb={0}>
                {title}
              </Heading.h2>
            )}

            {subText && (
              <Text fontSize="xs" color="blueGrey.slateGrey">
                {subText}
              </Text>
            )}
          </Col>

          {children && <Col ml="auto">{children}</Col>}
        </Row>
      </Container>
    </Box>
  );
};

export default PageTitle;
