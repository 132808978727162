import { Box, BoxProps, Flex, FlexProps } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

const RowStyled = styled(Flex)<FlexProps>``;

export const Row = ({
  flexWrap = 'wrap' as any,
  ml = -2,
  mr = -2,
  ...props
}) => {
  return <RowStyled flexWrap={flexWrap} ml={ml} mr={mr} {...props} />;
};

interface ColProps extends BoxProps {
  px?: number | string;
}

export const Col: React.FC<ColProps> = ({ px = 2, ...props }) => (
  <Box px={px} {...props} />
);
