import { rem } from 'polished';
import { Icon, IconProps } from '@qga/roo-ui/components';
import { border, BorderProps } from 'styled-system';
import toNumber from 'lodash/toNumber';

import styled from '@emotion/styled/macro';

interface IProps extends BorderProps, IconProps {}

const IconCircleStyled = styled(Icon)<IProps>`
  padding: ${(props) => rem(toNumber(props.size!) / 10)};
  border-color: currentColor;
  border-radius: 50%;

  ${border};
`;

export const IconCircle: React.FC<IProps> = ({
  border = 3,
  size = 24,
  ...props
}) => {
  return <IconCircleStyled border={border} size={size} {...props} />;
};
