import { Box, Icon, Link, Text } from '@qga/roo-ui/components';
import { Link as RouterLink } from 'react-router-dom';
import { MouseEventHandler, useMemo } from 'react';
import { rem } from 'polished';
import { Status } from 'src/__generated__/graphqlTypes';
import pluralize from 'pluralize';
import sentenceCase from 'sentence-case';
import { usePropertyMenuDetails } from './usePropertyMenuDetails';

export const StatusIndicator = ({
  trackClick,
}: {
  trackClick: MouseEventHandler<HTMLAnchorElement>;
}) => {
  const {
    propertyStatus,
    shouldShowOnboardingLink,
    shouldShowPreviousPropertyLink,
    showDropdown,
    stepsRemaining,
    propertyId,
  } = usePropertyMenuDetails();

  const iconStyles = useMemo(() => {
    switch (propertyStatus) {
      case Status.Published:
        return { color: 'ui.success', name: 'checkCircle' };
      case Status.Archived:
        return { color: 'greys.steel', name: 'removeCircle' };
      default:
        return { color: 'ui.error', name: 'pauseCircleFilled' };
    }
  }, [propertyStatus]);

  return (
    <Box
      bg="greys.porcelain"
      py={1}
      px={3}
      borderRadius={rem('100px')}
      data-testid="status-indicator"
    >
      <Icon {...iconStyles} size={20} />
      <Text color="blueGrey.slateGrey" px={1}>
        {sentenceCase(propertyStatus || '')}
      </Text>

      {showDropdown && <Icon name="keyboardArrowDown" />}

      {shouldShowOnboardingLink && (
        <Link
          underline={true}
          as={RouterLink}
          to={`/properties/${propertyId}`}
          onClick={trackClick}
          data-testid="onboarding-link"
        >
          {pluralize('step', stepsRemaining, true)} remaining
        </Link>
      )}

      {shouldShowPreviousPropertyLink && (
        <Link
          as={RouterLink}
          underline={true}
          to={`/properties/${propertyId}`}
          onClick={trackClick}
        >
          Previous property
        </Link>
      )}
    </Box>
  );
};
