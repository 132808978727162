import React from 'react';
import { List, ListItem, ListProps } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

interface IProps extends ListProps {
  prefix: string;
}

export const DecimalListStyled = styled(({ prefix, ...rest }) => (
  <List {...rest} />
))<IProps>`
  list-style-type: none;
  counter-reset: item;

  > ${ListItem} {
    counter-increment: item;

    &:before {
      font-weight: bold;
      content: '${(props) => props.prefix}.' counter(item) '. ';
    }
  }
`;

export const DecimalList = ({ flush = true, ordered = true, ...props }) => {
  return <DecimalListStyled flush={flush} ordered={ordered} {...props} />;
};
