import { Box } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import Portal from '@reach/portal';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled/macro';

const Base = styled(Box)`
  z-index: ${themeGet('zIndices.overlay')};
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`;

interface OverlayProps {
  children?: ReactNode;
}

const Overlay = (props: OverlayProps) => (
  <Portal>
    <Base {...props} />
  </Portal>
);

export default Overlay;
