import { rem } from 'polished';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  Box,
  Container,
  ExternalLink,
  Flex,
  Hide,
  Image,
  Link,
} from '@qga/roo-ui/components';
import { jetstar, qantas, qantasHotels } from '@qga/roo-ui/logos';

import { ContactLink } from 'src/components/ContactLink';

export const Footer: React.FC = () => (
  <Box bg="white" borderTop={1} borderColor="greys.alto">
    <Container maxWidth="wide">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        height={['auto', rem('105px')]}
      >
        <Hide xs={true} sm={true}>
          <Image src={qantasHotels} width={rem('186px')} height={rem('30px')} />
        </Hide>

        <Box flex="1 1 0" color="greys.steel" textAlign="center" py={5}>
          <ContactLink underline={true} mx={2}>
            Contact
          </ContactLink>

          <Link as={RouterLink} underline={true} mx={2} to="/terms">
            Terms of use
          </Link>

          <Link as={HashLink} underline={true} mx={2} to="/terms#privacy">
            Privacy policy
          </Link>
        </Box>

        <Hide xs={true} sm={true}>
          <Box textAlign="right">
            <ExternalLink href="https://www.qantas.com/hotels">
              <Image
                inline={true}
                src={qantas}
                width={rem('155px')}
                height={rem('30px')}
              />
            </ExternalLink>

            <ExternalLink href="https://www.jetstar.com/hotels">
              <Image
                inline={true}
                src={jetstar}
                width={rem('112px')}
                height={rem('30px')}
                ml={4}
              />
            </ExternalLink>
          </Box>
        </Hide>
      </Flex>
    </Container>
  </Box>
);
