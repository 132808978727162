import { usePropertyLayoutData } from '../../usePropertyLayoutData';
import { Status } from 'src/__generated__/graphqlTypes';
import { filter } from 'lodash';
import { usePropertyIdAlwaysInt } from 'src/hooks/usePropertyId';

interface UsePropertyMenuDetails {
  canDelete: boolean;
  canPublish: boolean;
  isArchived: boolean;
  isInvited: boolean;
  isOnboarding: boolean;
  isProspective: boolean;
  isPublished: boolean;
  isUnpublished: boolean;
  propertyId: number;
  propertyName?: string;
  propertyStatus?: Status;
  shouldShowOnboardingLink: boolean;
  shouldShowPreviousPropertyLink: boolean;
  showDropdown: boolean;
  stepsRemaining: number;
}

export const usePropertyMenuDetails = (): UsePropertyMenuDetails => {
  const { property, user } = usePropertyLayoutData();
  const propertyId = usePropertyIdAlwaysInt();

  const isArchived = property?.status === Status.Archived;
  const isOnboarding = property?.status === Status.Onboarding;
  const isUnpublished = property?.status === Status.Unpublished;
  const isPublished = property?.status === Status.Published;
  const isInvited = property?.status === Status.Invited;
  const isProspective = property?.status === Status.Prospective;
  const showDropdown = !isArchived && user?.isStaff;
  const stepsRemaining = filter(
    property?.publishable,
    (publishableItem) => !publishableItem,
  ).length;
  const canPublish = (isOnboarding || isUnpublished) && !stepsRemaining;
  const canDelete = !isPublished && !isUnpublished;
  const propertyStatus = property?.status;
  const isStaff = user?.isStaff;
  const previousPropertyId = property?.previousPropertyId;
  const shouldShowOnboardingLink = isOnboarding;
  const shouldShowPreviousPropertyLink = !!previousPropertyId && isStaff;
  const propertyName = property?.name;

  return {
    canDelete,
    canPublish,
    isArchived,
    isInvited,
    isOnboarding,
    isProspective,
    isPublished,
    isUnpublished,
    propertyId,
    propertyName,
    propertyStatus,
    shouldShowOnboardingLink,
    shouldShowPreviousPropertyLink,
    showDropdown,
    stepsRemaining,
  };
};
