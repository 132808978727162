import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Hide,
  Label,
  Link,
  Paragraph,
} from '@qga/roo-ui/components';
import { Formik } from 'formik';
import { InferType } from 'yup';
import {
  CognitoError,
  OtpCodeError,
  RecaptchaError,
  LockedOutError,
} from 'src/components/SignInErrorAlerts';
import InputField from 'src/fields/InputField';
import { PasswordField } from 'src/fields/PasswordField';
import useRecaptcha from 'src/hooks/useRecaptcha';
import { useAnalytics } from 'src/hooks/useAnalytics';
import {
  formSchema as legacySchema,
  formSchemaEnhanced,
} from './lib/formSchema';
import { PasswordRequirement } from '../PasswordRequirement';
import { maskEmailAddress } from './lib/maskHelper';
import { Form } from 'src/components/Form';

type Props = {
  valueType: 'email' | 'code'; // email is used for the new password reset experience
  value: string;
  onResetPassword: (
    email: string,
    code: string,
    password: string,
  ) => Promise<any>;
};

enum ErrorTypes {
  Generic = 'GENERIC',
  OtpCode = 'OTP_CODE',
  PasswordReuse = 'PWRD_REUSE',
  LockedOut = 'LIMIT_EXCPTN',
  InvalidPassword = 'INVALID_PWRD',
}

const ResetPasswordForm = ({ valueType, value, onResetPassword }: Props) => {
  const { trackPasswordResetResendEmail, trackPasswordResetUpdate } =
    useAnalytics();
  const [hasError, setHasError] = useState<ErrorTypes | undefined>(undefined);
  const [tempPasswords, setTempPasswords] = useState({});
  const { validateAction, hasRecaptchaError } = useRecaptcha();

  const onPasswordUpdated = (name: string, parsedValue: string | null) => {
    setTempPasswords({
      ...tempPasswords,
      [name]: parsedValue,
    });
  };

  const formSchema = valueType === 'email' ? formSchemaEnhanced : legacySchema;
  type FormValues = InferType<typeof formSchema>;

  return (
    <Formik<FormValues>
      validationSchema={formSchema}
      initialValues={formSchema.cast({})}
      onSubmit={async (formValues, { setSubmitting, setFieldError }) => {
        try {
          const { validateToken } = await validateAction(
            'qh_extranet_reset_password',
          );

          if (validateToken?.success) {
            trackPasswordResetUpdate();

            if (valueType === 'email') {
              await onResetPassword(
                value,
                formValues.code,
                formValues.password,
              );
            } else {
              await onResetPassword(
                formValues.email,
                value,
                formValues.password,
              );
            }

            setHasError(undefined);
          }
        } catch (error: unknown) {
          if (error && typeof error === 'object' && 'code' in error) {
            let formError = ErrorTypes.Generic;
            if (error?.code === 'CodeMismatchException')
              formError = ErrorTypes.OtpCode;
            else if (error?.code === 'PasswordHistoryPolicyViolationException')
              formError = ErrorTypes.PasswordReuse;
            else if (error?.code === 'LimitExceededException')
              formError = ErrorTypes.LockedOut;
            else if (error?.code === 'InvalidPasswordException')
              formError = ErrorTypes.InvalidPassword;

            setHasError(formError);
            if (formError === ErrorTypes.PasswordReuse) {
              setFieldError(
                'password',
                'New password must be different from your previous 6 passwords',
              );
            } else if (formError === ErrorTypes.InvalidPassword) {
              setFieldError(
                'password',
                'For security reasons, this password cannot be used. Please try a different one.',
              );
            }
          } else {
            console.warn(error);
          }
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <>
            <Form name="ResetPasswordForm" autoComplete="off">
              <Flex>
                <Flex flexWrap="wrap" width={[1, 1 / 2]}>
                  {hasError === ErrorTypes.Generic && <CognitoError />}
                  {hasError === ErrorTypes.OtpCode && <OtpCodeError />}
                  {hasError === ErrorTypes.LockedOut && <LockedOutError />}
                  {hasRecaptchaError && <RecaptchaError />}

                  <Box width={1}>
                    {valueType === 'email' ? (
                      <Label textStyle="text" mb={6}>
                        Please enter the code sent to{' '}
                        <strong>{maskEmailAddress(value)}</strong> and enter a
                        new password.
                      </Label>
                    ) : (
                      <InputField
                        label="Enter your email"
                        name="email"
                        suppressTracking
                      />
                    )}

                    {valueType === 'email' && (
                      <Box borderBottom={1} borderColor="border" mb={6}>
                        <InputField
                          label="Verification code"
                          name="code"
                          placeholder="Enter code"
                          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                          type="number"
                          suppressTracking
                        />

                        <Label textStyle="text">
                          Didn't get a code?{' '}
                          <Link
                            as="a"
                            href="/forgot-password"
                            onClick={trackPasswordResetResendEmail}
                          >
                            Resend
                          </Link>
                        </Label>

                        <Paragraph color="greys.steel" mb={6}>
                          Still haven’t received an email? Try checking your
                          junk and spam folders or{' '}
                          <Link
                            as="a"
                            href="/contact-us"
                            target="_blank"
                            underline
                          >
                            contact us
                          </Link>
                          .
                        </Paragraph>
                      </Box>
                    )}

                    <PasswordField
                      label="Create new password"
                      name="password"
                      onChange={onPasswordUpdated}
                      autoComplete="new-password"
                    />

                    <PasswordField
                      label="Re-enter new password"
                      name="passwordConfirmation"
                      onChange={onPasswordUpdated}
                      autoComplete="new-password"
                    />
                  </Box>

                  <Box width={[1, 1, 1, 3 / 5]} my={4}>
                    <Button
                      variant="primary"
                      block={true}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Updating...' : 'Update password'}
                    </Button>
                  </Box>
                </Flex>

                <Flex
                  width={[0, 1 / 2]}
                  pl={[0, 12]}
                  flexDirection="column"
                  justifyContent="end"
                  pb={16}
                >
                  <Hide xs={true}>
                    <PasswordRequirement input={tempPasswords} />
                  </Hide>
                </Flex>
              </Flex>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ResetPasswordForm;
