import { useMutation } from '@apollo/client';
import { ArchivePropertyDocument } from 'src/graphql/mutations/ArchiveProperty';
import { DeletePropertyDocument } from 'src/graphql/mutations/DeleteProperty';
import { PublishPropertyDocument } from 'src/graphql/mutations/PublishProperty';
import { UnpublishPropertyDocument } from 'src/graphql/mutations/UnpublishProperty';
import { usePropertyIdAlwaysInt } from 'src/hooks/usePropertyId';
import { usePropertyMenuDetails } from './usePropertyMenuDetails';

interface Action {
  confirmationLabel: string;
  confirmationMessage: string;
  confirmationTitle: string;
  execute: () => Promise<void>;
  loading: boolean;
  loadingMessage: string;
}

export type PropertyActionType =
  | 'archive'
  | 'delete'
  | 'publish'
  | 'unpublish'
  | '';

export const usePropertyActions = ({
  action,
}: {
  action: PropertyActionType;
}): Action => {
  const propertyId = usePropertyIdAlwaysInt();
  const { propertyName } = usePropertyMenuDetails();

  const [unpublishProperty, unpublishResponse] = useMutation(
    UnpublishPropertyDocument,
    {
      variables: { id: propertyId },
    },
  );
  const [publishProperty, publishResponse] = useMutation(
    PublishPropertyDocument,
    {
      variables: { id: propertyId },
    },
  );

  const [deleteProperty, deleteResponse] = useMutation(DeletePropertyDocument, {
    variables: { id: propertyId },
  });

  const [archiveProperty, archiveResponse] = useMutation(
    ArchivePropertyDocument,
    {
      variables: { id: propertyId },
    },
  );

  const getDropdownAction = (action: PropertyActionType): Action => {
    switch (action) {
      case 'archive':
        return {
          confirmationLabel: 'Archive',
          confirmationMessage: `Are you sure you want to archive ${propertyName}?`,
          confirmationTitle: 'Archive property',
          execute: async () => {
            await archiveProperty();
          },
          loading: archiveResponse.loading,
          loadingMessage: 'Archiving...',
        };
      case 'delete':
        return {
          confirmationLabel: 'Delete',
          confirmationMessage: `Are you sure you want to delete ${propertyName}?`,
          confirmationTitle: 'Delete property',
          execute: async () => {
            await deleteProperty();
          },
          loading: deleteResponse.loading,
          loadingMessage: 'Deleting...',
        };
      case 'publish':
        return {
          confirmationLabel: 'Publish',
          confirmationMessage: `Are you sure you want to publish ${propertyName}?`,
          confirmationTitle: 'Publish property',
          execute: async () => {
            await publishProperty();
          },
          loading: publishResponse.loading,
          loadingMessage: 'Publishing...',
        };
      case 'unpublish':
        return {
          confirmationLabel: 'Unpublish',
          confirmationMessage: `Are you sure you want to unpublish ${propertyName}?`,
          confirmationTitle: 'Unpublish property',
          execute: async () => {
            await unpublishProperty();
          },
          loading: unpublishResponse.loading,
          loadingMessage: 'Unpublishing...',
        };

      default:
        return {
          confirmationLabel: '',
          confirmationMessage: '',
          confirmationTitle: '',
          execute: async () => Promise.resolve(),
          loading: false,
          loadingMessage: '',
        };
    }
  };

  return getDropdownAction(action);
};
