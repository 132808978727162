import React, { ReactNode, useContext, useMemo } from 'react';
import {
  useViewStateManager,
  State,
  PropertyState,
} from './useViewStateManager';

type ActionCreators = {
  dismissRatePlansViewSummary: () => void;
  toggleRatesAndInventory: (id: string) => void;
};

type ContextType = [
  state: State['global'] & PropertyState,
  actions: ActionCreators,
];

const Context = React.createContext<ContextType>(
  null as unknown as ContextType,
);

export function useAppViewState() {
  return useContext(Context);
}

interface AppViewStateProviderProps {
  children?: ReactNode;
}

export const AppViewStateProvider = (props: AppViewStateProviderProps) => {
  const { state, actions } = useViewStateManager();
  const contextValue = useMemo<ContextType>(() => {
    return [state, actions];
  }, [state, actions]);

  return (
    <Context.Provider value={contextValue}>{props.children}</Context.Provider>
  );
};
