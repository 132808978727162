import { PasswordInput } from '@qga/roo-ui/components';
import React from 'react';

import Field, { Props } from 'src/fields/Field';

export const PasswordField: React.FC<Props> = (props) => (
  <Field
    as={PasswordInput}
    autoComplete="current-password"
    {...props}
    suppressTracking
  />
);
