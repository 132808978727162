import React, { ReactNode } from 'react';
import {
  Box,
  Flex,
  Hide,
  Image,
  Link,
  NakedButton,
} from '@qga/roo-ui/components';
import Nav from '../AuthenticatedLayout/components/Nav';
import Header from '../AuthenticatedLayout/components/Header';
import { rem } from 'polished';
import { qantasTail } from '@qga/roo-ui/logos';
import EmailVerificationNotice from '../AuthenticatedLayout/components/EmailVerificationNotice';
import { Shimmer } from 'src/primitives/Shimmer';
import PropertyGroupLink from './components/PropertyGroupLink';
import PropertySummary from './components/PropertySummary';
import { usePropertyLayoutData } from './usePropertyLayoutData';
import { PropertyActionsMenu } from './components/PropertyActionsMenu';

interface PropertyLayoutProps {
  children?: ReactNode;
}

export const PropertyLayout = ({ children }: PropertyLayoutProps) => {
  const { handleToggleNav, loading, navItems, navOpen, property, user } =
    usePropertyLayoutData();

  return (
    <>
      <Link hidden={true} href="#nav">
        Navigation
      </Link>

      <Link hidden={true} href="#profileMenu">
        Profile menu
      </Link>

      <Link hidden={true} href="#main">
        Main content
      </Link>

      <Flex flexDirection="row" minHeight="100vh">
        {Boolean(navItems.length) && (
          <Nav items={navItems} onToggle={handleToggleNav} open={navOpen} />
        )}

        <Flex
          flex="1 1 100%"
          flexDirection="column"
          minWidth={0}
          maxWidth="100%"
          position="relative"
        >
          <Header>
            {Boolean(navItems.length) && (
              <Hide md lg>
                <NakedButton onClick={handleToggleNav} verticalAlign="middle">
                  <Flex alignItems="center">
                    <Image
                      width={[rem('40px'), rem('50px')]}
                      src={qantasTail}
                      alt="Qantas"
                      mr={1}
                    />

                    <Link as="span">Menu</Link>
                  </Flex>
                </NakedButton>
              </Hide>
            )}

            {loading && (
              <Box>
                <Shimmer width={200} />
                <Shimmer width={75} />
              </Box>
            )}
            {!loading && property && (
              <Hide xs sm>
                <Flex justifyContent="center" alignItems="center">
                  {user.propertyGroupId && (
                    <PropertyGroupLink propertyGroupId={user.propertyGroupId} />
                  )}
                  <PropertySummary id={property.id} name={property.name} />

                  <Hide md>
                    <PropertyActionsMenu />
                  </Hide>
                </Flex>
              </Hide>
            )}
          </Header>

          {!user.emailVerified && <EmailVerificationNotice />}

          <Flex flex="0 1 100%" flexDirection="column" id="main">
            {children}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
