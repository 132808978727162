import { ConfirmationModal } from 'src/components/ConfirmationModal';
import { Dropdown } from 'src/components/Dropdown';
import { Link as RouterLink } from 'react-router-dom';
import { PropertyActionType, usePropertyActions } from './usePropertyActions';
import { StatusIndicator } from './StatusIndicator';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { useCallback, useState } from 'react';
import { usePropertyMenuDetails } from './usePropertyMenuDetails';

export const PropertyActionsMenu = (): JSX.Element => {
  const { trackNavMenuClick } = useAnalytics();
  const trackClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      trackNavMenuClick({
        groupName: 'Property Status Nav',
        text: e.currentTarget.innerText,
        url: e.currentTarget.href || window.location.href,
      });
    },
    [trackNavMenuClick],
  );

  const {
    canDelete,
    canPublish,
    isInvited,
    isProspective,
    isPublished,
    isUnpublished,
    propertyId,
    showDropdown,
  } = usePropertyMenuDetails();

  const [currentAction, setCurrentAction] = useState<PropertyActionType>('');

  const {
    confirmationLabel,
    confirmationMessage,
    confirmationTitle,
    execute,
    loading,
    loadingMessage,
  } = usePropertyActions({
    action: currentAction,
  });

  const handleDropdownAction = (
    action: PropertyActionType,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    trackClick(event);
    setCurrentAction(action);
  };

  const handleMutation = async () => {
    await execute();
    setCurrentAction('');
  };

  const manageUserUrl = `/properties/${propertyId}/user`;

  if (!showDropdown) {
    return <StatusIndicator trackClick={trackClick} />;
  }

  return (
    <>
      <Dropdown placement="left-start">
        <StatusIndicator trackClick={trackClick} />

        {canPublish && (
          <Dropdown.item
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              handleDropdownAction('publish', e);
            }}
          >
            Publish
          </Dropdown.item>
        )}
        {isPublished && (
          <Dropdown.item
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              handleDropdownAction('unpublish', e);
            }}
          >
            Unpublish
          </Dropdown.item>
        )}

        {isProspective && (
          <Dropdown.item
            as={RouterLink}
            to={manageUserUrl}
            onClick={trackClick}
          >
            Invite user
          </Dropdown.item>
        )}

        {isInvited && (
          <Dropdown.item
            as={RouterLink}
            to={manageUserUrl}
            onClick={trackClick}
          >
            Manage user
          </Dropdown.item>
        )}

        {canDelete && (
          <Dropdown.item
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              handleDropdownAction('delete', e);
            }}
          >
            Delete
          </Dropdown.item>
        )}

        {isUnpublished && (
          <Dropdown.item
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              handleDropdownAction('archive', e);
            }}
          >
            Archive
          </Dropdown.item>
        )}
      </Dropdown>

      <ConfirmationModal
        action={handleMutation}
        isOpen={!!confirmationTitle}
        label={confirmationLabel}
        loading={loading}
        loadingMessage={loadingMessage}
        message={confirmationMessage}
        onRequestClose={() => setCurrentAction('')}
        title={confirmationTitle}
      />
    </>
  );
};
