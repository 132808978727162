import { rem } from 'polished';
import React from 'react';
import { Image, ImageProps, Text } from '@qga/roo-ui/components';
import { qantasTail } from '@qga/roo-ui/logos';

import styled from '@emotion/styled/macro';

interface IProps extends ImageProps {
  src?: string;
}

const Logo = styled(Image)<IProps>`
  vertical-align: bottom;
`;

export const QantasHotelsLogo = ({ src = qantasTail }) => (
  <Text fontSize={['lg', 'xl']}>
    <Logo inline={true} width={[rem('40px'), rem('50px')]} src={src} />
    Qantas Hotels Extranet
  </Text>
);
