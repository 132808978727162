import { useLazyQuery } from '@apollo/client';
import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { ErrorOverlay } from 'src/components/ErrorOverlay';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import { ManagePropertyDocument } from 'src/graphql/queries/ManageProperty';
import useAuth from 'src/hooks/useAuth';
import { UseProperty_PropertyFragment } from './useProperty_Property';
import { usePropertyId } from '../usePropertyId';

type Property = UseProperty_PropertyFragment;

export const PropertyContext = createContext<Property>({} as Property);

const useProperty = () => useContext(PropertyContext);

interface PropertyProviderProps {
  children?: ReactNode;
}

export const PropertyProvider = ({ children }: PropertyProviderProps) => {
  const { isAuthenticated } = useAuth();
  const propertyId = usePropertyId();

  const [fetchData, { data, error, called, loading }] = useLazyQuery(
    ManagePropertyDocument,
  );

  useEffect(() => {
    if (propertyId && isAuthenticated) {
      fetchData({ variables: { id: propertyId } });
    }
  }, [fetchData, isAuthenticated, propertyId]);

  if (!propertyId || !isAuthenticated) return <>{children}</>;
  if (error) return <ErrorOverlay />;
  if (!called || loading) return <LoadingOverlay />;

  const property = data?.viewer?.property;

  return (
    <>
      {property && (
        <PropertyContext.Provider value={property}>
          {children}
        </PropertyContext.Provider>
      )}
    </>
  );
};

export default useProperty;
