import React from 'react';
import { Flex } from '@qga/roo-ui/components';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { HeaderActions } from './components/HeaderActions';
import Banners from './components/Banners';
import { PageView } from '../../components/PageView';

interface IProps {
  children: React.ReactNode;
  showLogos?: boolean;
  showBanners?: boolean;
  title: string;
}

const UnauthenticatedLayout = ({
  children,
  title,
  showBanners = false,
}: IProps) => (
  <PageView title={title}>
    <Flex flexDirection="column" minHeight="100vh">
      <Header>
        <HeaderActions />
      </Header>

      <Flex
        flex="1 1 auto"
        flexDirection="column"
        bg="greys.porcelain"
        id="main"
      >
        {children}
      </Flex>

      {showBanners && <Banners />}

      <Footer />
    </Flex>
  </PageView>
);

export default UnauthenticatedLayout;
