import React, { ReactNode } from 'react';
import { config } from 'src/config';
import {
  createInstance,
  OptimizelyProvider,
  useDecision,
} from '@optimizely/react-sdk';

export type FeatureFlag =
  | 'supplier-extranet-custom-inclusions'
  | 'supplier-extranet-global-inclusions'
  | 'supplier-extranet-reservations';

export type FeatureFlagValue = 'on' | 'off';

const optimizelyClient = createInstance({
  sdkKey: config.REACT_APP_OPTIMIZELY_DATAFILE_ID,
  eventBatchSize: 100,
  eventFlushInterval: 3000,
  odpOptions: {
    disabled: true,
  },
});

interface FeatureFlagProps {
  children?: ReactNode;
}

export const FeatureFlagProviderOptimizely = ({
  children,
}: FeatureFlagProps) => {
  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{ id: 'default_user' }}
    >
      {children}
    </OptimizelyProvider>
  );
};

export const useFeatureFlag = (feature: FeatureFlag) => {
  const [decision, clientReady] = useDecision(feature, { autoUpdate: true });

  const loaded = clientReady;
  const enabled = decision['enabled'];

  return [enabled, loaded];
};
