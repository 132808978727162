import React from 'react';
import { Icon } from '@qga/roo-ui/components';

interface IProps {
  size?: number;
}

export const Avatar = ({ size = 30 }: IProps) => (
  <Icon name="accountCircle" size={size} color="greys.alto" />
);
