import React from 'react';
import { Checkbox } from '@qga/roo-ui/components';

import RadioField from 'src/fields/RadioField';

interface Props extends React.ComponentProps<typeof RadioField> {
  hideLabel?: boolean;
  onBlur?: () => void;
  onChange?: () => void;
  tabIndex?: number;
  mb?: number;
  checked?: (
    formValue: string | boolean,
    value?: string | number | readonly string[] | undefined,
  ) => boolean;
  component?: typeof Checkbox;
  type?: 'checkbox';
}

export const CheckboxField: React.FC<Props> = ({
  hideLabel = false,
  onBlur = () => undefined,
  onChange = () => undefined,
  tabIndex = 0,
  mb = 4,
  checked = (
    formValue: string | boolean,
    value?: string | number | readonly string[] | undefined,
  ) => formValue === true,
  component = Checkbox,
  type = 'checkbox',
  ...props
}) => (
  <RadioField
    hideLabel={hideLabel}
    onBlur={onBlur}
    onChange={onChange}
    tabIndex={0}
    mb={4}
    checked={checked}
    component={component}
    type={type}
    {...props}
  />
);
