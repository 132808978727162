import { connect, FormikContextType } from 'formik';
import isFunction from 'lodash/isFunction';
import { rem } from 'polished';
import React from 'react';
import { Button, Container, OutlineButton } from '@qga/roo-ui/components';

import { Col, Row } from 'src/primitives/Grid';
import { Panel } from 'src/primitives/Panel';

interface IOuterProps {
  clearable?: boolean;
  label?: string | ((isSubmitting: boolean) => string);
  sticky?: boolean;
}

interface IFormikProps {
  formik: FormikContextType<any>;
}

export const Actions = ({
  formik,
  clearable = true,
  sticky = true,
  label = (submitting: boolean) => (submitting ? 'Saving...' : 'Save'),
}: IOuterProps & IFormikProps) => {
  return (
    <Panel highlight={true} sticky={sticky}>
      <Container>
        <Row maxWidth={['auto', rem('480px')]}>
          <Col flex={1}>
            <Button
              data-testid="submit-form"
              block={true}
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting || !formik.dirty}
            >
              {isFunction(label) ? label(formik.isSubmitting) : label}
            </Button>
          </Col>

          <Col flex={1}>
            {clearable && formik.dirty && (
              <OutlineButton
                data-testid="clear-form"
                block={true}
                variant="primary"
                onClick={formik.handleReset}
                disabled={formik.isSubmitting}
              >
                Clear changes
              </OutlineButton>
            )}
          </Col>
        </Row>
      </Container>
    </Panel>
  );
};

export const FormActions = connect<IOuterProps>(Actions);
FormActions.displayName = 'FormActions';
