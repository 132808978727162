import styled from '@emotion/styled/macro';
import { rem } from 'polished';
import React, { ReactNode } from 'react';
import { Box, BoxProps, Container, Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { ProfileMenu } from '../ProfileMenu';

const Wrapper = styled(Box)<BoxProps>`
  position: sticky;
  top: 0;
  z-index: ${themeGet('zIndices.header')};
  white-space: nowrap;
`;

interface HeaderProps {
  children?: ReactNode;
}

const Header = ({ children }: HeaderProps) => (
  <Wrapper as="header" boxShadow="soft" bg="white">
    <Container>
      <Flex alignItems="center" height={[rem('60px'), rem('80px')]}>
        <Box minWidth={0}>{children}</Box>

        <Box ml="auto" pl={4}>
          <ProfileMenu />
        </Box>
      </Flex>
    </Container>
  </Wrapper>
);

export default Header;
