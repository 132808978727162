import { List, ListItem, ListProps } from '@qga/roo-ui/components';

import styled from '@emotion/styled/macro';

export const AlphaListStyled = styled(List)<ListProps>`
  list-style-type: none;
  counter-reset: item;

  > ${ListItem} {
    counter-increment: item;

    &:before {
      font-weight: bold;
      content: '(' counter(item, lower-alpha) ') ';
    }
  }
`;

export const AlphaList = ({ ordered = true, ...props }) => {
  return <AlphaListStyled ordered={ordered} {...props} />;
};
