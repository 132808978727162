import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Flex,
  Heading,
  Link,
  NakedButton,
  Paragraph,
} from '@qga/roo-ui/components';

import { ContactLink } from 'src/components/ContactLink';
import { GENERIC_ERROR_MESSAGE } from 'src/components/GenericError';
import { Footer } from 'src/layouts/UnauthenticatedLayout/components/Footer';
import { Header } from 'src/layouts/UnauthenticatedLayout/components/Header';

export interface IErrorPageProps {
  message?: string;
  tryAgain?: () => any;
}

const refresh = () => window.location.reload();

export const ErrorPage = ({
  message = GENERIC_ERROR_MESSAGE,
  tryAgain,
}: IErrorPageProps) => (
  <Flex
    minHeight="100vh"
    bg="greys.porcelain"
    flexDirection="column"
    justifyContent="space-between"
  >
    <Header />

    <Container maxWidth="wide" py={10}>
      <Heading.h1 fontSize="xxl" mb={10}>
        {message}
      </Heading.h1>

      <Paragraph fontSize="lg">
        Please{' '}
        {tryAgain ? (
          <NakedButton onClick={tryAgain}>
            <Link as="span">try again</Link>
          </NakedButton>
        ) : (
          <NakedButton onClick={refresh}>
            <Link as="span">refresh the page</Link>
          </NakedButton>
        )}
        , or <ContactLink /> if the problem persists.
      </Paragraph>

      <Paragraph fontSize="lg">
        Head back to{' '}
        <Link as={RouterLink} to="/">
          Qantas Hotels Extranet
        </Link>
        .
      </Paragraph>
    </Container>

    <Footer />
  </Flex>
);
