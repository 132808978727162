import React from 'react';
import { Alert, Link } from '@qga/roo-ui/components';
import { Link as RouterLink } from 'react-router-dom';

export const OtpCodeError = () => (
  <Alert variant="error" data-testid="verification-code-error">
    <strong>Invalid or expired code</strong>
    <br />
    The code you entered is invalid or has expired. Check the code and try
    again, or{' '}
    <Link as={RouterLink} to="/forgot-password" underline={true}>
      resend a new code
    </Link>
    .
  </Alert>
);

export const CognitoError = () => (
  <Alert variant="error" data-testid="error">
    <strong>Invalid email or password</strong>
    <br />
    Please check your credentials and try again.
  </Alert>
);

export const RecaptchaError = () => (
  <Alert variant="error" data-testid="recaptcha-error">
    <strong>Anti-spam verification failed</strong>
    <br />
    There was an error verifying reCAPTCHA. Please try signing in again.
  </Alert>
);

export const LockedOutError = () => (
  <Alert variant="error" data-testid="locked-out-error">
    <strong>Access temporarily blocked</strong>
    <br />
    Too many failed attempts. Please try again later.
  </Alert>
);

export const CompromisedError = () => (
  <Alert variant="error" data-testid="compromised-error">
    <strong>Insecure password detected</strong>
    <br />
    To protect your account, please{' '}
    <Link as={RouterLink} to="/forgot-password" underline={true}>
      change your password
    </Link>
    .
  </Alert>
);
